
import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import MetaHoldingModel from '@/models/Keystone_v2/MetaHoldingModel'
import HoldingModel from '@/models/Keystone_v2/HoldingModel'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'
import AdvertiserModel from '@/models/Keystone_v2/AdvertiserModel'
import { entityAutocompleteMixin } from '@/mixins/entityAutocompleteMixin'
import { AutocompleteEntityTypes } from '../../../../types/entity_types'
import type SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import SiegeModel from '@/models/Keystone_v2/SiegeModel'

export type dataType = {[x: string]: string}

@Component
export default class ChildEntityAutocomplete extends mixins(entityAutocompleteMixin) {
  @Prop()
  entitySearchType: string
  @Prop()
  itemText: string
  @Prop()
  itemValue: string
  @Prop({ default: false })
  allowRemoving: boolean

  [x: string]: any
  loading: boolean = false

  async onChangeSearch (search: string) {
    /*
      Sometimes when search is null, entity is not set to null (removeAdvertiser).
      That create a paradox where entity is not a part of list and entity not displayed, so we can't add him to our list.
    */
    if (search == null) {
      this.entity = null
    }

    if (this.canLaunchSearch) {
      this.loading = true
      this.result = []

      const data = {
        search: search.toString().trim()
      }

      const response = await this.getSearchedEntities(data)

      if (response != null && this.$apiCaller.isResponseError(response, true)) {
        console.warn('Error or call cancelled search', this.entitySearchType)
        if (!this.$apiCaller.isCancelError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        this.response = this.newModel(response.results)
      }
      this.loading = false
    }
  }

  async getSearchedEntities (data: dataType) {
    switch (this.entitySearchType) {
      case 'metaholding':
        return this.$apiCaller.getMetaHoldings(data)
      case 'holding':
        return this.$apiCaller.getHoldings(data)
      case 'company':
        return this.$apiCaller.getCompanies(data)
      case 'member':
        return this.$apiCaller.getKeystoneMemberSettings(data)
      case 'seat':
        return this.$apiCaller.getSieges(data)
      case 'advertisers':
        return this.$apiCaller.getAdvertisers(data)
      case 'advertiser':
      case 'agency':
        return this.$apiCaller.getAdvertisersOfMember(this.searchParentId, data)
    }
  }

  newModel (result: any): any {
    switch (this.entitySearchType) {
      case 'metaholding':
        return result.data.map((item: MetaHoldingModel) => new MetaHoldingModel(item)).sort((a: any, b: any) => a.name.localeCompare(b.name))
      case 'holding':
        return result.data.map((item: HoldingModel) => new HoldingModel(item)).sort((a: any, b: any) => a.name.localeCompare(b.name))
      case 'company':
        return result.data.map((item: CompanyModel) => new CompanyModel(item)).sort((a: any, b: any) => a.name.localeCompare(b.name))
      case 'member':
        return result.data.map((item: MemberSettingModel) => item.member)
      case 'seat':
        return result.data.map((item: SiegeModel) => new SiegeModel(item)).sort((a: any, b: any) => a.name.localeCompare(b.name))
      case 'advertisers':
        return result.data.map((item: AdvertiserModel) => new AdvertiserModel(item)).sort((a: any, b: any) => a.name.localeCompare(b.name))
      case 'advertiser':
        return result.data.map((item: AdvertiserModel) => new AdvertiserModel(item)).sort((a: any, b: any) => a.name.localeCompare(b.name))
      case 'agency':
        const agencyList = [...(new Set(result.data.filter((item: any) => item.agency_id).map((item: any) => item.agency_id)))]
        return agencyList.map((item) => {
          return Number(item)
        })
    }
  }

  itemTextFunction (entity: AutocompleteEntityTypes) {
    if (this.itemText != null) {
      return this.itemText
    } else if (this.entitySearchType === 'advertiser') {
      return `(${(entity as AdvertiserModel).externalId}) ${entity.name}`
    } else if (this.entitySearchType === 'agency') {
      return (entity as any).text
    }
    return entity.name ?? null
  }
  itemValueFunction (entity: AutocompleteEntityTypes) {
    if (this.itemValue != null) {
      return this.itemText
    } else if (this.entitySearchType === 'agency') {
      return (entity as any).value
    }
    return entity.id
  }

  removeEntity () {
    this.entity = null
  }

  // GETTERS
  get label () {
    return this.entitySearchType[0].toUpperCase() + this.entitySearchType.substr(1).toLowerCase()
  }
  get noDataText () {
    return 'Search for a ' + this.entitySearchType + ' type here'
  }

  // WATCHERS
  @Watch('searchParentId')
  async onSearchParentIdChange (searchParentId: number) {
    await this.onChangeSearch(this.search)
  }
}

