import { ProactiveIo, ProactiveIoComments, ResultProactiveIo, ResultProactiveIos } from '../types/proactive_io_types'
import HoldingModel from '@/models/Keystone_v2/HoldingModel'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import MemberModel from '@/models/Keystone_v2/MemberModel'
import SiegeModel from '@/models/Keystone_v2/SiegeModel'
import AdvertiserModel from '@/models/Keystone_v2/AdvertiserModel'

function formatData (elem: ResultProactiveIo) {
  let dataIo: ProactiveIo = {
    id: elem.id,
    clientName: elem.client_name,
    country: '',
    region: '',
    holding: '',
    company: '',
    seat: '',
    member: '',
    dsp: '',
    advertiser: ''
  }
  if (elem.siege) {
    dataIo.holding = new HoldingModel(elem.siege.company.holding)
    dataIo.company = new CompanyModel(elem.siege.company)
    if (elem.siege.company.country) {
      dataIo.country = elem.siege.company.country.name
      dataIo.region = elem.siege.company.country.region.name
    }
    dataIo.member = new MemberModel(elem.member)
    dataIo.seat = new SiegeModel(elem.siege)
    dataIo.dsp = elem.member ? elem.member.dsp : 'NC'
  } else {
    if (elem.holding) {
      dataIo.holding = new HoldingModel(elem.holding)
    } else if (elem.company) {
      dataIo.holding = new HoldingModel(elem.company.holding)
      dataIo.company = new CompanyModel(elem.company)
      if (elem.company.country) {
        dataIo.country = elem.company.country.name
        dataIo.region = elem.company.country.region.name
      }
    } else if (elem.advertiser) {
      dataIo.holding = new HoldingModel(elem.advertiser.siege.company.holding)
      dataIo.company = new CompanyModel(elem.advertiser.siege.company)
      if (elem.advertiser.siege.company.country) {
        dataIo.country = elem.advertiser.siege.company.country.name
        dataIo.region = elem.advertiser.siege.company.country.region.name
      }
      dataIo.member = new MemberModel(elem.advertiser.member)
      dataIo.seat = new SiegeModel(elem.advertiser.siege)
      dataIo.dsp = elem.advertiser.member.dsp
      dataIo.advertiser = new AdvertiserModel(elem.advertiser)
    }
  }
  return dataIo
}

export function formatResultProactiveIo (result: ResultProactiveIo) {
  return formatData(result)
}
export function formatResultProactiveIos (result: ResultProactiveIos) {
  return result.data.map((elem: any) => {
    return formatResultProactiveIo(elem)
  })
}

export function formatComments (comments : Array<ProactiveIoComments>) {
  return comments.map((proactive) => {
    return {
      id: proactive.id,
      proactiveIo: proactive.proactive_io.id,
      author: proactive.author,
      comment: proactive.comment,
      insertionDate: proactive.insertion_date,
      lastUpdateDate: proactive.last_update_date
    }
  })
}
