import SiegeModel from '@/models/Keystone_v2/SiegeModel'

export default class AdvertiserModel {
  constructor (data) {
    this.id = data.id
    this.name = data.name
    this.externalId = data.external_id
    this.siege = new SiegeModel(data && data.siege ? data.siege : {})
  }
}
