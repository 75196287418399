
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CommentsProactiveIO from '@/components/ProactiveInterface/CommentsProactiveIO.vue'
import { ProactiveMessageIo } from '../../../types/proactive_io_types'
import EditCommentModal from '@/components/ProactiveInterface/EditCommentModal.vue'
import { formatComments } from '../../../utils/proactiveIoUtils'
@Component({
  components: { EditCommentModal, CommentsProactiveIO }
})
export default class NoteIoDialog extends Vue {
  @Prop({}) id : number

  dialog: boolean = false
  comments: Array<ProactiveMessageIo> = []
  isUpdated: boolean = false

  async getProactiveIoComments (id: number) {
    const result = await this.$apiCaller.getProactiveIosComments(id)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling ProactiveSupportIO')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      if (result.data) {
        this.comments = formatComments(result.data)
      }
    }
  }

  openDialog (id: number) {
    this.dialog = true
    this.getProactiveIoComments(id)
  }

  closeModal () {
    this.dialog = false
  }
  @Watch('isUpdated')
  updateComments () {
    this.getProactiveIoComments(this.id)
    this.isUpdated = false
  }
}
