
import { Vue, Component, Watch } from 'vue-property-decorator'
import EntityAutocomplete from '@/components/KeystoneV2/EntityAutocomplete.vue'
import DeleteProactiveModal from '@/components/ProactiveInterface/DeleteProactiveModal.vue'
import NoteIoDialog from '@/components/ProactiveInterface/NoteIoDialog.vue'
import { mixins } from 'vue-class-component'
import { usersMixin } from '@/mixins/usersMixin'
import { ProactiveIo, ResultProactiveIos } from '../../types/proactive_io_types'
import EditProactiveIO from '@/components/ProactiveInterface/EditProactiveIO.vue'
import { formatResultProactiveIos } from '../../utils/proactiveIoUtils'
import store from '../../store'
import { keystoneV2 } from '../../store/modules/keystoneV2'
import baseViewMixin from '@/mixins/baseViewMixin'

if (!store.state.keystoneV2) store.registerModule('keystoneV2', keystoneV2)
@Component({
  components: { EditProactiveIO, NoteIoDialog, DeleteProactiveModal, EntityAutocomplete },
  mixins: [baseViewMixin]
})
export default class ProactifSupportIO extends mixins(usersMixin) {
  disabledAutoSearch: boolean = false
  removeIo: boolean = false
  updateIo: boolean = false
  addIo: boolean = false
  snackbar: boolean = false
  snackbarMessage = ''

  headers = [
    { text: 'Rule name', value: 'clientName', width: '15px' },
    { text: 'Country', value: 'country' },
    { text: 'Region', value: 'region' },
    { text: 'Holding', value: 'holding.name' },
    { text: 'Company', value: 'company.name', filterable: false },
    { text: 'DSP', value: 'member.dsp' },
    { text: 'Seat', value: 'member.externalId' },
    { text: 'Advertiser', value: 'advertiser.externalId', sortable: false },
    { text: 'Notes', value: 'notes', sortable: false },
    { text: '', value: 'edit', sortable: false }
  ]

  data: Array<ProactiveIo> = []

  search = ''

  async mounted () {
    /*
      This method come from the mixin (baseViewMixin) defined as a definedComponent
      and typescript is not able to link it with a class component.
     */
    // @ts-ignore
    this.onMounted()
    await this.getProactiveIos()
  }

  async getProactiveIos () {
    const result = await this.$apiCaller.getProactiveIos()
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling ProactiveSupportIO')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      if (result.data) {
        this.data = formatResultProactiveIos(result)
      }
    }
  }

  closeSnackBar () {
    this.snackbar = false
    this.snackbarMessage = ''
  }

  @Watch('addIo')
  onAddData () {
    this.getProactiveIos()
    this.snackbarMessage = 'You successfully add a proactive io rule !'
    this.snackbar = true
    this.addIo = false
  }
  @Watch('updateIo')
  onUpdateData () {
    this.getProactiveIos()
    this.snackbarMessage = 'You successfully update a proactive io rule !'
    this.snackbar = true
    this.updateIo = false
  }

  @Watch('removeIo')
  onRemoveData () {
    this.getProactiveIos()
    this.snackbarMessage = 'You successfully remove a proactive io rule !'
    this.snackbar = true
    this.removeIo = false
  }
}

