
import { Vue, Component, Prop, Watch, VModel } from 'vue-property-decorator'
import CommentsProactiveIO from '@/components/ProactiveInterface/CommentsProactiveIO.vue'
import { ProactiveIo } from '../../../types/proactive_io_types'
import ChildEntityAutocomplete from '@/components/KeystoneV2/tools/ChildEntityAutocomplete.vue'
import { rulesMixin } from '@/mixins/rulesMixin'
import { AutocompleteEntityTypes } from '../../../types/entity_types'
import { formatResultProactiveIo } from '../../../utils/proactiveIoUtils'

@Component({
  components: { ChildEntityAutocomplete, CommentsProactiveIO },
  mixins: [rulesMixin]
})
export default class EditProactiveIO extends Vue {
  @Prop({}) edit: boolean
  @Prop({}) proactiveId: number

  @VModel()
  updateIo: boolean

  data: ProactiveIo | null
  dialog: boolean = false
  filterLevel: string = ''
  entitySelected: any = ''
  comments: string = null
  level: Array<string> = [
    'holding',
    'company',
    'seat',
    'advertiser'
  ]

  name: string = ''
  country: string = ''
  region: string = ''
  seat: string = ''
  holding: string = ''
  company: string = ''
  advertiser: string = ''
  isValid = false
  search : AutocompleteEntityTypes
  errors : Array<string> = []

  openDialog () {
    this.dialog = true
  }

  showCountryAndRegion () {
    return this.filterLevel !== 'holding' && this.filterLevel !== ''
  }

  setFilters () {
    this.setFilterLevel()
    this.name = this.data.clientName
    if (this.filterLevel === 'holding') {
      this.entitySelected = this.data.holding
    } else if (this.filterLevel === 'company') {
      this.entitySelected = this.data.company
    } else if (this.filterLevel === 'seat') {
      this.entitySelected = this.data.seat
    } else if (this.filterLevel === 'advertiser') {
      this.entitySelected = this.data.advertiser
    }
  }
  setFilterLevel () {
    if (this.data.advertiser) {
      this.filterLevel = 'advertiser'
    } else if (this.data.seat) {
      this.filterLevel = 'seat'
    } else if (this.data.company) {
      this.filterLevel = 'company'
    } else if (this.data.holding) {
      this.filterLevel = 'holding'
    }
  }

  cleanFields () {
    this.name = ''
    this.country = ''
    this.region = ''
    this.seat = ''
    this.holding = ''
    this.company = ''
    this.advertiser = ''
    this.comments = ''
    this.filterLevel = ''
    this.entitySelected = ''
  }
  closeModal () {
    if (!this.edit) {
      this.cleanFields()
    }
    this.dialog = false
  }

  errorMessageValueCantBeEmpty (value: any, label: string) {
    let errors : Array<string> = []
    if (!value) {
      errors.push(`The field "${label}" must be filled !`)
    }
    return errors
  }

  async validate () {
    if (this.name && this.filterLevel && this.entitySelected) {
      if (!this.edit) {
        await this.createProactiveIo()
        this.cleanFields()
      } else {
        await this.updateProactiveIo()
      }
      this.closeModal()
    }
  }
  async getProactiveIo () {
    const result = await this.$apiCaller.getProactiveIo(this.proactiveId)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling get ProactiveSupportIO')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      if (result.data) {
        this.data = formatResultProactiveIo(result.data)
      }
    }
  }
  async createProactiveIo () {
    const proactiveComment = this.comments ? { 'comment': this.comments, 'author': this.userMail } : null
    const result = await this.$apiCaller.postProactiveIos(this.name, this.holding, this.company, this.seat, this.advertiser, proactiveComment)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling ProactiveSupportIO')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      this.updateIo = true
    }
  }

  async updateProactiveIo () {
    const result = await this.$apiCaller.putProactiveIos(this.name, this.holding, this.company, this.seat, this.advertiser, this.proactiveId)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling ProactiveSupportIO')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      this.updateIo = true
    }
  }

  get entitySearchType () {
    return (this.filterLevel === 'advertiser') ? 'advertisers' : this.filterLevel
  }

  @Watch('entitySelected')
  getFilterID (entitySelected: any) {
    if (entitySelected === undefined) {
      this.entitySelected = ''
    } else {
      this.holding = null
      this.company = null
      this.seat = null
      this.advertiser = null
      if (this.filterLevel === 'holding') {
        this.holding = entitySelected.id
      } else if (this.filterLevel === 'company') {
        this.country = entitySelected.country?.name
        this.region = entitySelected.country?.region?.name
        this.company = entitySelected.id
      } else if (this.filterLevel === 'seat') {
        if (this.entitySelected.company) {
          this.country = entitySelected.company.country?.name
          this.region = entitySelected.company.country?.region?.name
        }
        this.seat = entitySelected.id
      } else if (this.filterLevel === 'advertiser') {
        this.advertiser = entitySelected.id
        if (entitySelected.siege) {
          this.country = entitySelected.siege.company.country?.name
          this.region = entitySelected.siege.company.country?.region?.name
        }
      }
    }
  }
  get showSaveButton () {
    return !(this.name && this.filterLevel && this.entitySelected)
  }

  get userMail () {
    return this.$store.getters.getCurrentUserMail
  }
  @Watch('dialog')
  async onDataReceived (dialog: boolean) {
    if (dialog === true && this.edit) {
      if (this.proactiveId) {
        await this.getProactiveIo()
        this.setFilters()
      }
    }
  }
}
