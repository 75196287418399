
import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator'
import { ProactiveMessageIo } from '../../../types/proactive_io_types'
import SimpleDialog from '@/components/TableComponents/Form/FormFragments/SimpleDialog.vue'

@Component({
  components: { SimpleDialog }
})
export default class EditCommentModal extends Vue {
  comment: string = ''
  dialog: boolean = false
  textEdited: boolean = false

  @VModel()
  isUpdated: boolean

  @Prop({ required: false })
  comments: ProactiveMessageIo

  @Prop()
  isEdit: boolean

  @Prop()
  proactiveId: number

  mounted () {
    if (this.comments && this.comments.comment) {
      this.comment = this.comments.comment
    } else {
      this.comment = ''
    }
  }
  openModal () {
    this.dialog = true
  }
  closeModal () {
    this.dialog = false
    this.comment = this.comments ? this.comments.comment : ''
  }

  save () {
    if (this.isEdit === true) {
      this.saveEditComments()
    } else {
      this.saveNewComments()
    }
  }
  async saveEditComments () {
    const result = await this.$apiCaller.putProactiveIoComment(this.proactiveId, this.comments.id, this.comment, this.comments.author)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling ProactiveSupportIOComments')
      this.$store.commit('setErrorMessageWithResponse', result)
    }
    this.isUpdated = true
    this.closeModal()
  }
  async saveNewComments () {
    const result = await this.$apiCaller.postProactiveIoComments(this.proactiveId, this.comment, this.userMail)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling ProactiveSupportIOComments')
      this.$store.commit('setErrorMessageWithResponse', result)
    }
    this.isUpdated = true
    this.dialog = false
  }

  get userMail () {
    return this.$store.getters.getCurrentUserMail
  }

  @Watch('comment')
  onCommentChange (comment: string) {
    this.textEdited = comment !== this.comments.comment && comment !== ''
  }
}
