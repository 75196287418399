import { render, staticRenderFns } from "./NoteIoDialog.vue?vue&type=template&id=1ab98e62&scoped=true"
import script from "./NoteIoDialog.vue?vue&type=script&lang=ts"
export * from "./NoteIoDialog.vue?vue&type=script&lang=ts"
import style0 from "./NoteIoDialog.vue?vue&type=style&index=0&id=1ab98e62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab98e62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VContainer,VDialog,VIcon})
