
import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator'

@Component({})
export default class DeleteProactiveModal extends Vue {
  @Prop({}) entity: string

  @VModel()
  removeIo: boolean

  @Prop({}) id: number

  dialog: boolean = false
  removed: boolean = false

  openDialog () {
    this.dialog = true
  }
  validateDeleteIO () {
    this.removed = true
  }
  closeModal () {
    this.dialog = false
  }
  @Watch('removed')
  async deleteProactiveIO (removeIo: boolean) {
    const result = await this.$apiCaller.deleteProactiveIos(this.id)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling ProactiveSupportIO')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      const barConfig = {
        type: 'success',
        message: 'You successfully remove a proactive io rule !'
      }
      this.$store.commit('setSnackBarConfig', barConfig)
      this.$store.commit('setActiveSnackBar', true)
      this.removeIo = true
      this.closeModal()
    }
  }
}
