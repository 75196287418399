
import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import { ProactiveMessageIo } from '../../../types/proactive_io_types'
import EditCommentModal from '@/components/ProactiveInterface/EditCommentModal.vue'
@Component({
  components: { EditCommentModal }
})
export default class CommentsProactiveIO extends Vue {
  @Prop({ required: true }) comments: ProactiveMessageIo

  @Prop({}) id: number

  @VModel()
  isUpdated: boolean

  removeDialog: boolean = false

  removeText: string = 'Are you sure to remove this comment ?'

  isAuthor () {
    return this.comments.author === this.userMail
  }

  openRemoveModal () {
    this.removeDialog = true
  }

  closeRemoveModal () {
    this.removeDialog = false
  }
  async deleteComment () {
    const result = await this.$apiCaller.deleteProactiveIoComment(this.comments.proactiveIo, this.comments.id)
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling ProactiveSupportIO')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      this.isUpdated = true
      this.closeRemoveModal()
    }
  }
  get userMail () {
    return this.$store.getters.getCurrentUserMail
  }
}
