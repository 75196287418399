import { apiCaller, Vue } from '../index'

/**
 * @param tabSearch {{text: string, id: number, isParent: boolean, source: string}}
 * @param entitiesToSearch {String} the entities to search. default ''. Can be 'metaholdings', 'holdings', 'companies', 'sieges', 'members'
 * @param idKey {String} the key used for put the id. default 'id'
 */
function getDataForApiWithTabSearch (tabSearch, entitiesToSearch = '', idKey = 'id') {
  let data = {}

  if (tabSearch && tabSearch.id) {
    if (tabSearch.isParent) {
      if (entitiesToSearch === 'sieges' && tabSearch.source === 'holding') {
        data['holding_id'] = tabSearch.id
      } else {
        data['parent_id'] = tabSearch.id
      }
    } else {
      data[idKey] = tabSearch.id
    }
  }

  data['source'] = tabSearch.source

  if (Object.keys(data).length === 0) {
    data = null
  }
  return data
}

function addLimitOffsetAndSearch (data, args) {
  if (args.limit) {
    data.limit = args.limit
  }

  if (args.offset) {
    data.offset = args.offset
  }

  if (args.search) {
    data.search = args.search
  }
  return data
}

function addSortBy (data, args) {
  if (args.sortBy) {
    data.sort_by = args.sortBy
    data.desc = !!args.desc
  }
  return data
}

function manageResponseEntity (response, commit, commitName) {
  if (apiCaller.isResponseError(response)) {
    commit('setErrorMessageWithResponse', response)
    commit(commitName, {
      data: [],
      count: 0,
      totalCount: 0
    })
  } else {
    commit(commitName, {
      data: response?.data,
      count: response?.count,
      totalCount: response?.total_count
    })
  }
}

const state = {
  metaHoldings: [],
  totalCountMetaHoldings: 0,
  holdings: [],
  totalCountHoldings: 0,
  companies: [],
  totalCountCompanies: 0,
  keystoneSieges: [],
  keystoneMembers: [],
  totalCountKeystoneSieges: 0,
  totalCountKeystoneMembers: 0,
  loadingMetaHoldings: false,
  loadingHoldings: false,
  loadingCompanies: false,
  loadingSieges: false,
  loadingMembers: false,
  /**
   * @type  { Object.<string, {text: string, id: number, isParent: boolean, source: string}> }
   */
  tabSearch: {
    metaHoldings: { text: '', id: null, isParent: false, source: '' },
    holdings: { text: '', id: null, isParent: false, source: '' },
    companies: { text: '', id: null, isParent: false, source: '' },
    sieges: { text: '', id: null, isParent: false, source: '' },
    members: { text: '', id: null, isParent: false, source: '' }
  },
  currentTab: null,
  autoSearch: '',
  loadingAutocomplete: false,
  autoResultItems: {
    metaHoldings: [],
    holdings: [],
    companies: [],
    sieges: [],
    members: []
  },
  activeSnackBar: false,
  snackBarConfig: {
    color: 'success',
    message: ''
  },
  countries: [],
  /**
   * @type {CollaboratorModel[]}
   */
  collaborators: [],
  loadingCountries: false,
  /**
   * @type {{
   *  open: boolean,
   *  type: 'new'|'edit',
   *  id: number|null,
   *  parentEntity: BaseKeystoneEntity|null,
   *  entityType: 'metaholding'|'holding'|'company'|'siege'|'member'|null
   * }}
   */
  openForm: {
    open: false,
    type: 'new',
    id: null,
    parentEntity: null,
    entityType: null
  },
  copiedBillingEntity: null
}

const actions = {
  async getApiMetaHoldings ({ commit, state }, args) {
    commit('setLoadingMetaHoldings', true)

    let data = getDataForApiWithTabSearch(state.tabSearch.metaHoldings, 'metaholdings')
    data = addLimitOffsetAndSearch(data, args)
    data = addSortBy(data, args)

    if (args.metaholdingId) {
      data.id = args.metaholdingId
    }
    const response = await apiCaller.getMetaHoldings(data)
    manageResponseEntity(response, commit, 'gotMetaHoldings')

    commit('setLoadingMetaHoldings', false)
  },
  async getApiHoldings ({ commit, state }, args) {
    commit('setLoadingHoldings', true)

    let data = getDataForApiWithTabSearch(state.tabSearch.holdings, 'holdings')
    data = addLimitOffsetAndSearch(data, args)
    data = addSortBy(data, args)

    if (args.holdingId) {
      data.id = args.holdingId
    }

    const response = await apiCaller.getHoldings(data)
    manageResponseEntity(response, commit, 'gotHoldings')

    commit('setLoadingHoldings', false)
  },
  async getApiCompanies ({ commit, state }, args) {
    commit('setLoadingCompanies', true)

    let data = getDataForApiWithTabSearch(state.tabSearch.companies, 'companies')
    data = addLimitOffsetAndSearch(data, args)
    data = addSortBy(data, args)

    if (args.companyId) {
      data.id = args.companyId
    }

    const response = await apiCaller.getCompanies(data)
    manageResponseEntity(response, commit, 'gotCompanies')

    commit('setLoadingCompanies', false)
  },
  async getApiKeystoneMembers ({ commit }, args) {
    commit('setLoadingMembers', true)

    let data = getDataForApiWithTabSearch(state.tabSearch.members, 'members', 'member_id')
    data = addLimitOffsetAndSearch(data, args)
    data = addSortBy(data, args)

    if (args.memberId) {
      data.member_id = args.memberId
    }

    const response = await apiCaller.getKeystoneMemberSettings(data)
    manageResponseEntity(response, commit, 'gotMembers')

    commit('setLoadingMembers', false)
  },
  async getApiKeystoneSieges ({ commit }, args) {
    commit('setLoadingSieges', true)

    let data = getDataForApiWithTabSearch(state.tabSearch.sieges, 'sieges')
    data = addLimitOffsetAndSearch(data, args)
    data = addSortBy(data, args)

    if (args.siegeId) {
      data.siege_id = args.siegeId
    }

    const response = await apiCaller.getKeystoneSiegeSettings(data)
    manageResponseEntity(response, commit, 'gotSieges')

    commit('setLoadingSieges', false)
  },
  async getAutoCompleteItems ({ commit, state }) {
    commit('setLoadingAutocomplete', true)
    const data = {
      search: state.autoSearch
    }
    const responseMetaHoldings = await apiCaller.getMetaHoldings(data)
    commit('setAutoResultItemsMetaHoldings', responseMetaHoldings.data.map(item => {
      return {
        ...item,
        type: 'metaholding'
      }
    }))

    const resultHoldings = await apiCaller.getHoldings(data)
    commit('setAutoResultItemsHoldings', resultHoldings.data.map(item => {
      return {
        ...item,
        type: 'holding'
      }
    }))

    const responseCompanies = await apiCaller.getCompanies(data)
    commit('setAutoResultItemsCompanies', responseCompanies.data.map(item => {
      return {
        ...item,
        type: 'company'
      }
    }))

    const responseSieges = await apiCaller.getKeystoneSiegeSettings(data)
    commit('setAutoResultItemsSieges', responseSieges.data.map(item => {
      return {
        ...item,
        type: 'siege'
      }
    }))

    const responseMembers = await apiCaller.getKeystoneMemberSettings(data)
    commit('setAutoResultItemsMembers', responseMembers.data.map(item => {
      return {
        ...item,
        type: 'member'
      }
    }))

    commit('setLoadingAutocomplete', false)
  },
  /**
   * @param commit
   * @param state
   * @param data @param data {{name: string, hasFitScore: boolean, hasInsight: boolean}}
   * @return {Promise<T>}
   */
  async createMetaHolding ({ commit, state }, data) {
    const result = await apiCaller.postMetaHoldingKeystoneV2(data)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when creating metaholding')
    } else {
      console.warn('success')
    }

    return result
  },
  /**
   * @param commit
   * @param state
   * @param data @param data {{id: number, name: string}}
   * @return {Promise<T>}
   */
  async updateMetaHolding ({ commit, state }, data) {
    let { id, ...apiData } = data
    const result = await apiCaller.putMetaHolding(apiData, id)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when updating metaholding')
    } else {
      console.warn('success')
    }

    return result
  },
  /**
   * @param commit
   * @param state
   * @param data @param data {{name: string, has_fit_score: boolean}}
   * @return {Promise<T>}
   */
  async createHolding ({ commit, state }, data) {
    const result = await apiCaller.postHoldingKeystoneV2(data)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when creating holding')
    } else {
      console.warn('success')
    }

    return result
  },
  /**
   * @param commit
   * @param state
   * @param data @param data {{id: number, name: string, has_fit_score: boolean, has_insight: boolean}}
   * @return {Promise<T>}
   */
  async updateHolding ({ commit, state }, data) {
    let { id, ...apiData } = data
    const result = await apiCaller.putHolding(apiData, id)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when updating holding')
    } else {
      console.warn('success')
    }

    return result
  },
  async getApiCountries ({ commit, state }, data) {
    const result = await apiCaller.getCountries()

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when getting countries')
    } else {
      console.warn('success')
      commit('gotCountries', result.data)
    }
  },
  async updateCompany ({ commit, state }, data) {
    let { id, ...apiData } = data
    const result = await apiCaller.putCompany(apiData, id)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when updating company')
    } else {
      console.warn('success')
    }

    return result
  },
  async createCompany ({ commit, state }, data) {
    const result = await apiCaller.postCompanyKeystoneV2(data)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when creating company')
    } else {
      console.warn('success')
    }

    return result
  },
  async getApiCollaborators ({ commit, state }, data) {
    const result = await apiCaller.getAllCollaborators()

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when getting collaborators')
    } else {
      console.warn('success')
      commit('gotCollaborators', result.data)
    }
  },
  async createSiege ({ commit, state }, data) {
    const apiData = {
      data: data
    }
    const result = await apiCaller.postKeystoneSiegeSettings(apiData)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when creating siege')
    } else {
      console.warn('success')
    }

    return result
  },
  async updateSiege ({ commit, state }, data) {
    let { id, ...apiData } = data
    const result = await apiCaller.putKeystoneSiegeSetting({
      data: apiData
    }, id)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when updating siege')
    } else {
      console.warn('success')
    }

    return result
  },
  async updateMember ({ commit, state }, data) {
    let { id, ...apiData } = data
    const result = await apiCaller.putKeystoneMemberSetting({
      data: apiData
    }, id)

    if (apiCaller.isResponseError(result)) {
      console.warn('Error when updating member')
    } else {
      console.warn('success')
    }

    return result
  }
}

const mutations = {
  /**
   * @param state
   * @param data {{data: object[], totalCount: number}}
   */
  gotMetaHoldings (state, data) {
    Vue.set(state, 'metaHoldings', data.data)
    Vue.set(state, 'totalCountMetaHoldings', data.totalCount)
  },
  /**
   * @param state
   * @param data {{data: object[], totalCount: number}}
   */
  gotHoldings (state, data) {
    Vue.set(state, 'holdings', data.data)
    Vue.set(state, 'totalCountHoldings', data.totalCount)
  },
  /**
   * @param state
   * @param data {{data: object[], totalCount: number}}
   */
  gotCompanies (state, data) {
    Vue.set(state, 'companies', data.data)
    Vue.set(state, 'totalCountCompanies', data.totalCount)
  },
  /**
   * @param state
   * @param data {{data: object[], totalCount: number}}
   */
  gotSieges (state, data) {
    Vue.set(state, 'keystoneSieges', data.data)
    Vue.set(state, 'totalCountKeystoneSieges', data.totalCount)
  },
  /**
   * @param state
   * @param data {{data: object[], totalCount: number}}
   */
  gotMembers (state, data) {
    Vue.set(state, 'keystoneMembers', data.data)
    Vue.set(state, 'totalCountKeystoneMembers', data.totalCount)
  },
  setLoadingMetaHoldings (state, loading) {
    Vue.set(state, 'loadingMetaHoldings', loading)
  },
  setLoadingHoldings (state, loading) {
    Vue.set(state, 'loadingHoldings', loading)
  },
  setLoadingCompanies (state, loading) {
    Vue.set(state, 'loadingCompanies', loading)
  },
  setLoadingSieges (state, loading) {
    Vue.set(state, 'loadingSieges', loading)
  },
  setLoadingMembers (state, loading) {
    Vue.set(state, 'loadingMembers', loading)
  },
  /**
   * @param state
   * @param search {{text: string, id: number}}
   */
  setTabSearchMetaHoldings (state, search) {
    Vue.set(state.tabSearch, 'metaholdings', search)
  },
  /**
   * @param state
   * @param search {{text: string, id: number}}
   */
  setTabSearchHoldings (state, search) {
    Vue.set(state.tabSearch, 'holdings', search)
  },
  /**
   * @param state
   * @param search {{text: string, id: number}}
   */
  setTabSearchCompanies (state, search) {
    Vue.set(state.tabSearch, 'companies', search)
  },
  /**
   * @param state
   * @param search {{text: string, id: number}}
   */
  setTabSearchSieges (state, search) {
    Vue.set(state.tabSearch, 'sieges', search)
  },
  /**
   * @param state
   * @param search {{text: string, id: number}}
   */
  setTabSearchMembers (state, search) {
    Vue.set(state.tabSearch, 'members', search)
  },
  /**
   * @param state
   * @param options {{search: {text: string, id: number}, type: 'metaholding'|'holding'|'company'|'siege'|'member'}}
   */
  setTabSearch (state, options) {
    let rel = {
      metaHolding: 'metaholdings',
      holding: 'holdings',
      company: 'companies',
      siege: 'sieges',
      member: 'members'
    }
    Vue.set(state.tabSearch, rel[options.type], options.search)
  },
  setCurrentTab (state, tab) {
    Vue.set(state, 'currentTab', tab)
  },
  setAutoSearch (state, search) {
    Vue.set(state, 'autoSearch', search)
  },
  setLoadingAutocomplete (state, value) {
    Vue.set(state, 'loadingAutocomplete', value)
  },
  setAutoResultItemsMetaHoldings (state, items) {
    Vue.set(state.autoResultItems, 'metaholdings', items)
  },
  setAutoResultItemsHoldings (state, items) {
    Vue.set(state.autoResultItems, 'holdings', items)
  },
  setAutoResultItemsCompanies (state, items) {
    Vue.set(state.autoResultItems, 'companies', items)
  },
  setAutoResultItemsSieges (state, items) {
    Vue.set(state.autoResultItems, 'sieges', items)
  },
  setAutoResultItemsMembers (state, items) {
    Vue.set(state.autoResultItems, 'members', items)
  },
  resetAutoResultItems (state) {
    const baseAutoResultItems = {
      metaHoldings: [],
      holdings: [],
      companies: [],
      sieges: [],
      members: []
    }
    Vue.set(state, 'autoResultItems', baseAutoResultItems)
  },
  setActiveSnackBar (state, value) {
    Vue.set(state, 'activeSnackBar', value)
  },
  setLoadingCountries (state, value) {
    Vue.set(state, 'loadingCountries', value)
  },
  /**
   * @param state
   * @param config {{color: 'success'|'error'|'warning'|'info', message: string}}
   */
  setSnackBarConfig (state, config) {
    Vue.set(state, 'snackBarConfig', config)
  },
  gotCountries (state, countries) {
    Vue.set(state, 'countries', countries)
  },
  gotCollaborators (state, collaborators) {
    Vue.set(state, 'collaborators', collaborators)
  },
  /**
   * @param state
   * @param openFormOptions
   * {{
   *  open: boolean,
   *  type: 'new'|'edit',
   *  id: number|null,
   *  parentEntity: BaseKeystoneEntity|null,
   *  entityType: 'metaholding'|'holding'|'company'|'siege'|'member'|null
   * }}
   */
  setOpenForm (state, openFormOptions) {
    Vue.set(state, 'openForm', openFormOptions)
  },
  resetOpenForm (state) {
    Vue.set(state, 'openForm', {
      open: false,
      type: 'new',
      id: null,
      parentEntity: null,
      entityType: null
    })
  },
  /**
   * @param state
   * @param metaHolding {MetaHoldingModel|null}
   */
  openNewFormHolding (state, metaHolding = null) {
    Vue.set(state, 'openForm', {
      open: true,
      id: null,
      type: 'new',
      parentEntity: metaHolding,
      entityType: 'holding'
    })
  },
  /**
   * @param state
   * @param holding {HoldingModel}
   */
  openEditFormHolding (state, holding) {
    Vue.set(state, 'openForm', {
      open: true,
      id: holding.id,
      type: 'edit',
      parentEntity: holding.metaHolding,
      entityType: 'holding'
    })
  },
  /**
   * @param state
   * @param holding {HoldingModel|null}
   */
  openNewFormCompany (state, holding = null) {
    Vue.set(state, 'openForm', {
      open: true,
      id: null,
      type: 'new',
      parentEntity: holding,
      entityType: 'company'
    })
  },
  /**
   * @param state
   * @param company {CompanyModel}
   */
  openEditFormCompany (state, company) {
    Vue.set(state, 'openForm', {
      open: true,
      id: company.id,
      type: 'edit',
      parentEntity: company.holding,
      entityType: 'company'
    })
  },
  /**
   * @param state
   * @param company {CompanyModel|null}
   */
  openNewFormSiege (state, company = null) {
    Vue.set(state, 'openForm', {
      open: true,
      id: null,
      type: 'new',
      parentEntity: company,
      entityType: 'siege'
    })
  },
  /**
   * @param state
   * @param siege {SiegeSettingModel}
   */
  openEditFormSiege (state, siege) {
    Vue.set(state, 'openForm', {
      open: true,
      id: siege.id,
      type: 'edit',
      parentEntity: siege.siege.company,
      entityType: 'siege'
    })
  },
  /**
   * @param state
   * @param memberId {Number}
   */
  openEditFormMember (state, memberId) {
    Vue.set(state, 'openForm', {
      open: true,
      id: memberId,
      type: 'edit',
      parentEntity: null,
      entityType: 'member'
    })
  },
  /**
   * @param state
   * @param billingEntity {BillingEntityModel}
   */
  setCopiedBillingEntity (state, billingEntity) {
    Vue.set(state, 'copiedBillingEntity', billingEntity)
  }
}

const getters = {
  getMetaHoldings (state) {
    return state.metaHoldings
  },
  getHoldings (state) {
    return state.holdings
  },
  getCompanies (state) {
    return state.companies
  },
  getSieges (state) {
    return state.keystoneSieges
  },
  getMembers (state) {
    return state.keystoneMembers
  },
  getLoadingMetaHoldings (state) {
    return state.loadingMetaHoldings
  },
  getLoadingHoldings (state) {
    return state.loadingHoldings
  },
  getLoadingCompanies (state) {
    return state.loadingCompanies
  },
  getLoadingSieges (state) {
    return state.loadingSieges
  },
  getLoadingMembers (state) {
    return state.loadingMembers
  },
  getTabSearchMetaHoldings (state) {
    return state.tabSearch.metaHoldings
  },
  getTabSearchHoldings (state) {
    return state.tabSearch.holdings
  },
  getTabSearchCompanies (state) {
    return state.tabSearch.companies
  },
  getTabSearchSieges (state) {
    return state.tabSearch.sieges
  },
  getTabSearchMembers (state) {
    return state.tabSearch.members
  },
  getCurrentTab (state) {
    return state.currentTab
  },
  getAutoSearch (state) {
    return state.autoSearch
  },
  getLoadingAutocomplete (state) {
    return state.loadingAutocomplete
  },
  getAutoResultItems (state) {
    return state.autoResultItems
  },
  getSnackBarConfig (state) {
    return state.snackBarConfig
  },
  getActiveSnackBar (state) {
    return state.activeSnackBar
  },
  getCountries (state) {
    return state.countries
  },
  getLoadingCountries (state) {
    return state.loadingCountries
  },
  getOpenForm (state) {
    return state.openForm
  },
  /**
   * @param state
   * @return {CollaboratorModel[]|{get: string}}
   */
  getCollaborators (state) {
    return state.collaborators
  },
  getTotalCountKeystoneSieges (state) {
    return state.totalCountKeystoneSieges
  },
  getTotalCountCompanies (state) {
    return state.totalCountCompanies
  },
  getTotalCountHoldings (state) {
    return state.totalCountHoldings
  },
  getTotalCountMetaHoldings (state) {
    return state.totalCountMetaHoldings
  },
  getTotalCountKeystoneMembers (state) {
    return state.totalCountKeystoneMembers
  },
  getCopiedBillingEntity (state) {
    return state.copiedBillingEntity
  }
}

export const keystoneV2 = {
  actions,
  mutations,
  state,
  getters
}
