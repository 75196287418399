export default class CheckUpdateEntity<T> {
  _originalStringified: string
  constructor () {
    this._originalStringified = ''
  }

  /**
   * to call at the end of the constructor
   * save the original object passed in param. used in hasBeenUpdated
   * can be call at every moment for refresh the original
   */
  saveOriginal () {
    let { _originalStringified, ...copyWithoutStringified } = this
    this._originalStringified = JSON.stringify(copyWithoutStringified)
  }

  hasBeenUpdated () {
    let { _originalStringified, ...copyWithoutStringified } = this
    return this._originalStringified !== JSON.stringify(copyWithoutStringified)
  }

  getThisWithoutOriginal () {
    let { _originalStringified, ...copyWithoutStringified } = this
    return copyWithoutStringified
  }

  getOriginal (): Omit<NonMethodProperties<T>, '_originalStringified'> {
    let original = null
    try {
      original = JSON.parse(this._originalStringified)
    } catch (error) {
      console.warn(`Error when parsing the original : ${JSON.parse(this._originalStringified)}`)
    }
    return original
  }
}
