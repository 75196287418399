import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { AutocompleteEntityTypes } from '../../types/entity_types'
import _ from 'lodash'

@Component
export class entityAutocompleteMixin extends Vue {
  @Prop()
  value: any
  @Prop()
  errorMessages: Array<string>
  @Prop()
  disabled: boolean
  @Prop()
  doNotAddEntityToResult: boolean
  @Prop()
  searchParentId: Number | String | null
  @Prop()
  itemDisabled: boolean
  @Prop({ default: null })
  returnValueIfNull: any

  entity: AutocompleteEntityTypes = null
  loading: boolean = false
  search: string = ''
  result: Array<AutocompleteEntityTypes> = []

  mounted () {
    this.entity = this.value
  }

  addCurrentEntityToResult () {
    if (this.doNotAddEntityToResult) {
      return
    }
    this.result = []
    this.result.push(this.entity)
  }
  async onChangeSearch (search: string) {
    console.warn('Not implemented')
  }

  // GETTERS
  get isSetSearch () {
    return this.search != null && typeof this.search === 'string' && this.search.toString().trim() !== ''
  }
  get canLaunchSearch () {
    if (this.search && this.entity == null) {
      return true
    }
    return this.isSetSearch && this.search !== this.entity.name
  }

  @Watch('value')
  onValueChange (value: AutocompleteEntityTypes) {
    if (value !== this.entity) {
      this.entity = value
    }
  }

  @Watch('search')
  onSearchChange = _.debounce(async (search: string) => {
    await this.onChangeSearch(search)
  }, 600)

  @Watch('entity', { deep: true })
  onEntityChange (value: any) {
    // When entity is null return empty object instead of null
    if (value == null) {
      this.$emit('input', this.returnValueIfNull)
    } else {
      console.log(value)
      this.$emit('input', value)
    }

    if (this.disabled || (!this.result.length && this.entity && this.entity.id && this.entity.name)) {
      this.addCurrentEntityToResult()
    }
  }
}
