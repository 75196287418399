<template>
  <v-autocomplete
    v-model="selectedAutoItem"
    :search-input.sync="autoSearchTimeout"
    prepend-icon="search"
    filled
    :items="autocompleteItems"
    :disabled="disable"
    :loading="$store.getters.getLoadingAutocomplete"
    return-object
    label="Search"
    :filter="customAutoFilter"
    no-data-text="Search for in all entity type here"
    no-filter
    chips
    solo
    clearable
  >
    <template v-slot:selection="data">
      <v-chip
        :input-value="data.selected"
        class="chip--select-multi"
      >
        <v-avatar v-if="data.item.avatar">
          <img alt="The avatar" :src="data.item.avatar">
        </v-avatar>
        {{ getItemName(data.item) }}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar v-if="data.item.avatar">
          <img alt="The avatar" :src="data.item.avatar">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="itemTextFunction(data.item)"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>

  </v-autocomplete>
</template>

<script>
import * as keystoneV2Utils from '../../../utils/keystoneV2Utils'

export default {
  name: 'EntityAutocomplete',
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      autoSearchTimeout: '',
      timeoutHandle: null,
      /**
       * @type {{type: 'siege'|'company'|'holding'|'member', id: number}}
       */
      selectedAutoItem: {}
    }
  },
  methods: {
    customAutoFilter (item, queryText, itemText) {
      // baseFilter
      const baseFilter = function (text, toSearch) {
        return text.toLocaleLowerCase().indexOf(toSearch.toLocaleLowerCase()) > -1
      }
      // for allowing display header
      const headerCond = ('header' in item)
      // for allowing divider
      const dividerCond = ('divider' in item)
      // for company
      const companiesFilter = ('holding' in item && baseFilter(item.holding.name, queryText))
      // for siege
      const siegesFilter = ('siege' in item && (baseFilter(item.siege.name, queryText) || baseFilter(item.siege.company.name, queryText) || baseFilter(item.siege.company.holding.name, queryText)))
      // for member
      const membersFilter = ('member' in item && baseFilter(item.member.name, queryText))
      return baseFilter(itemText, queryText) || headerCond || companiesFilter || siegesFilter || membersFilter || dividerCond
    },
    itemTextFunction (item) {
      const reg = new RegExp(this.autoSearch, 'gi')

      const hightlightTemplate = function (template) {
        const result = String(template).match(reg)
        if (Array.isArray(result) && result.length) {
          const resultCleaned = [...new Set(result.map(item => String(item).toLowerCase()))]
          for (let i in resultCleaned) {
            let reg = new RegExp(result[i], 'g')
            template = template.replace(reg, `<span class="hightlight-auto">${result[i]}</span>`)
          }
        }
        return template
      }

      const templateEntity = function (entity) {
        return `${hightlightTemplate(entity.name)} <span class="id-autocomplete">${hightlightTemplate(entity.id)}</span>`
      }

      const tipTemplate = function (text, tip) {
        return `<span title="${tip}">${hightlightTemplate(text)}</span>`
      }

      if ('type' in item) {
        if (item.type === 'holding') {
          return `${templateEntity(item)}`
        } else if (item.type === 'company') {
          return `${templateEntity(item)} | ${tipTemplate('(h)', 'holding')} ${templateEntity(item.holding)}`
        } else if (item.type === 'siege') {
          return `${templateEntity(item.siege)} | ${tipTemplate('(c)', 'company')} ${templateEntity(item.siege.company)} | ${tipTemplate('(h)', 'holding')} ${templateEntity(item.siege.company.holding)}`
        } else if (item.type === 'member') {
          return `${templateEntity(item.member)} | ${item.member.external_id}`
        } else {
          console.warn('No type detected')
          return hightlightTemplate(item.name)
        }
      }
      return item
    },
    getItemName (item) {
      return keystoneV2Utils.getNameForEntity(item, item.type)
    }
  },
  computed: {
    autoSearch: {
      get () {
        return this.$store.getters.getAutoSearch
      },
      set (newValue) {
        this.$store.commit('setAutoSearch', newValue)
      }
    },
    autocompleteItems () {
      const autocompleteItems = []
      const entityGroup = this.$store.getters.getAutoResultItems

      for (let entityType in entityGroup) {
        if (!entityGroup.hasOwnProperty(entityType)) {
          continue
        }
        let group = entityGroup[entityType]
        const header = { header: entityType !== 'sieges' ? entityType : 'seats' }
        const divider = { divider: true, dividerType: entityType }
        if (group.length) {
          autocompleteItems.push(header)
          autocompleteItems.push(...group)
          autocompleteItems.push(divider)
        }
      }
      return autocompleteItems
    }
  },
  watch: {
    autoSearchTimeout: function (autoSearchTimeout) {
      if (!autoSearchTimeout) {
        return
      }

      if (this.timeoutHandle !== null) {
        clearTimeout(this.timeoutHandle)
      }

      this.timeoutHandle = window.setTimeout(function () {
        this.autoSearch = autoSearchTimeout.trim()
      }.bind(this), 400)
    },
    autoSearch: function (autoSearch) {
      if (autoSearch) {
        this.$store.dispatch('getAutoCompleteItems')
      } else {
        console.warn('resetAutoResult')
        this.$store.commit('resetAutoResultItems')
      }
    },
    selectedAutoItem: {
      handler: function (selectedAutoItem) {
        if (!selectedAutoItem) {
          return
        }
        const tabNum = keystoneV2Utils.getTabForEntity(this.selectedAutoItem.type)
        const id = this.selectedAutoItem.type !== 'member' ? this.selectedAutoItem.id : this.selectedAutoItem.member.id
        const search = {
          text: keystoneV2Utils.getNameForEntity(this.selectedAutoItem, this.selectedAutoItem.type),
          id: id
        }
        this.$store.commit('setTabSearch', { search: search, type: this.selectedAutoItem.type })
        this.$store.commit('setCurrentTab', tabNum)
      }
    }
  }
}
</script>

<style scoped>

</style>

<style>
.id-autocomplete {
  font-size: 12px;
  color: #9f9e9e;
  padding: 2px;
}

.hightlight-auto {
  background: #e6e6e6;
  padding: 2px;
}
</style>
